/*
 * @Author: your name
 * @Date: 2021-04-15 10:01:52
 * @LastEditTime: 2021-04-15 10:03:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/store/modules/app.js
 */



const app = {
  state: {

    excludeName: '' //保存缓存的列表
  },
  mutations: {

  },
  actions: {

  }
}

export default app
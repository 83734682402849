<!--
 * @Author: your name
 * @Date: 2021-04-15 18:30:11
 * @LastEditTime: 2021-04-20 10:19:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/components/page/GlobalHeader.vue
-->
<template>
  <div
    class="global_header"
    ref="globalHeader"
    :style="{ background: backgroundColor }"
  >
    <div class="header_inner">
      <div class="pconly">
        <div class="menu_list">
          <div class="logo"></div>
          <div class="menu_list_item is_active">
            解決方案
            <a-icon class="icon" type="caret-down" />
          </div>
          <div class="menu_list_item">
            數據安全
            <a-icon class="icon" type="caret-down" />
          </div>
          <div class="menu_list_item">最新資訊</div>
          <div class="menu_list_item">關於我們</div>
        </div>
      </div>
      <a-dropdown
        :getPopupContainer="(node) => node.parentNode"
        :trigger="['click']"
        class="menu_list_item lang_item"
      >
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <a-icon style="margin-right: 8px" type="global" />
          簡體中文
          <a-icon class="icon" type="caret-down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item key="0" disabled> 简体中文 </a-menu-item>
          <a-menu-item key="1"> 繁體中文 </a-menu-item>
          <a-menu-item key="2" disabled> English </a-menu-item>
        </a-menu>
      </a-dropdown>
      <!-- <div class="menu_list_item lang_item">
        <a-icon style="margin-right: 8px" type="global" />
        簡體中文
        <a-icon class="icon" type="caret-down" />
        <div class="">111</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalHeader",
  data() {
    return {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      
      if (scrollTop > 59) {
        this.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      } else {
        this.backgroundColor = 'rgba(0, 0, 0, 0)';
      }
    },
  },
};
</script>
<style lang="less" scoped>
.global_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  .header_inner {
    display: flex;
    height: 60px;
    line-height: 60px;
    width: 1200px;
    margin: 0 auto;
    padding-left: 8px;
    .pconly {
      flex: 1;
      height: 100%;
      display: flex;
      .menu_list {
        position: relative;
        display: inline-flex;
        color: #fff;
        height: 100%;
        align-items: center;
        .logo {
          height: 100%;
          background-image: url(~@/assets/logo.png);
          background-repeat: no-repeat;
          background-size: contain;
          width: 175px;
          background-position: 50%;
          cursor: pointer;
          margin-right: 50px;
        }
        & > .menu_list_item::before {
          content: "";
          position: absolute;
          left: 27px;
          bottom: 0;
          width: calc(100% - 27px);
          height: 3px;
          background-color: #3152d4;
          opacity: 0;
        }
        & > .menu_list_item.is_active::before {
          opacity: 1;
        }
      }
    }
    .menu_list_item {
      position: relative;
      font-size: 16px;
      padding: 0 0 0 27px;
      margin-right: 27px;
      cursor: pointer;
      .icon {
        margin-left: 8px;
      }
    }
    .menu_list_item.lang_item {
      color: #fff;
      margin-right: 0;
    }
    .language_inner {
      background-repeat: no-repeat;
      background-size: contain;
      width: 120px;
      background-position: 50%;
      cursor: pointer;
    }
  }
}
</style>


<!--
 * @Author: your name
 * @Date: 2021-04-15 09:58:13
 * @LastEditTime: 2021-04-15 14:14:02
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/components/layouts/RouteView.vue
-->
<template>
  <global-layout>
    <transition name="page-transition">
      <keep-alive v-if="keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </transition>
  </global-layout>
</template>

<script>
import GlobalLayout from "@/components/page/GlobalLayout";
export default {
  name: "RouteView",
  components: {
    GlobalLayout,
  },
  computed: {
    keepAlive() {
      console.log(this.$route.meta.keepAlive);
      return this.$route.meta.keepAlive;
    },
  },
};
</script>
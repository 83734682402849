/*
 * @Author: your name
 * @Date: 2021-04-15 09:59:19
 * @LastEditTime: 2021-04-15 10:00:57
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/store/getters.js
 */

const getters = {
  // token: state => state.user.token,
}

export default getters
/*
 * @Author: your name
 * @Date: 2021-04-13 14:11:03
 * @LastEditTime: 2021-04-19 17:16:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button } from 'ant-design-vue';
import { Carousel } from 'ant-design-vue';
import { Icon } from 'ant-design-vue';
import { Tooltip } from 'ant-design-vue';
import { Progress } from 'ant-design-vue';
import { Row } from 'ant-design-vue';
import { Col } from 'ant-design-vue';
import { Input } from 'ant-design-vue';
import { Dropdown } from 'ant-design-vue';
import { Menu } from 'ant-design-vue';
Vue.config.productionTip = false
Vue.use(Button);
Vue.use(Carousel);
Vue.use(Icon);
Vue.use(Tooltip);
Vue.use(Progress);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(Menu);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

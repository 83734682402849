<!--
 * @Author: your name
 * @Date: 2021-04-15 10:47:05
 * @LastEditTime: 2021-04-20 11:12:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/components/page/GlobalLayout.vue
-->
<template>
  <div class="global_layout">
    <global-header />
    <div>
      <slot></slot>
    </div>
    <global-footer />
  </div>
</template>

<script>
import GlobalHeader from "@/components/page/GlobalHeader";
import GlobalFooter from "@/components/page/GlobalFooter";
export default {
  name: "GlobalLayout",
  components: { GlobalHeader, GlobalFooter },
  data() {
    return {
      collapsed: false,
      activeMenu: {},
      menus: [],
    };
  },

  created() {},
  methods: {},
};
</script>

<style lang="less">
.global_layout {
  min-height: 100vh;
  width: 100%;
  min-width: 1200px;
  // max-width: 1920px;
  // overflow-x: hidden;
  margin: auto;
}
</style>

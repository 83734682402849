/*
 * @Author: your name
 * @Date: 2021-04-13 14:11:03
 * @LastEditTime: 2021-04-15 10:17:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { RouteView } from '@/components/layouts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: RouteView,
    meta: { title: 'Home', },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: { title: 'Home', },
        component: () => import('@/views/home/Index.vue'),
      }
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

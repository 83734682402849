<!--
 * @Author: your name
 * @Date: 2021-04-16 18:16:13
 * @LastEditTime: 2021-04-19 11:44:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/components/page/GlobalFooter.vue
-->
<template>
  <div class="global_footer">
    <div class="inner">
      <div class="nav">
        <span v-for="(item, idx) in navList" :key="idx">{{ item }}</span>
      </div>
      <div class="content">
        <div class="left">
          <img class="logo_img" src="@/assets/logo_blue.png" alt="" srcset="" />
          <div class="third_party">
            <img
              v-for="(item, index) in thirdPartyList"
              :key="index"
              :src="item.img"
            />
          </div>
        </div>
        <div class="right">
          <div class="copyright">
            <!-- <span>&copy;2017 - 2018 BeeFintech Company Limited （Hong Kong）</span> -->
            <span>&copy;2017 - 2024 汇赢（深圳）信息科技发展有限公司</span>
          </div>
          <div class="beian">
            <!-- <span>&copy;2017 - 2018 BeeFintech Company Limited （Hong Kong）</span> -->
            <a href="https://beian.miit.gov.cn/" target="_blank">
              粤ICP备2021112093号-1
            </a>
          </div>
          <div class="address">
            <img
              src="@/assets/images/home/address.png"
              width="18px"
              height="20px"
            />
            <!-- <span>香港九龍塘達之路72號創新中心1樓 LION ROCK 72</span> -->
            <!-- <span>深圳市福田区福鑫苑3楼</span> -->
            <span>香港九龍塘達之路72號創新中心5樓 FinTech Centre</span>
          </div>
          <div class="email">
            <img
              src="@/assets/images/home/email.png"
              width="20px"
              height="15px"
            />
            <div>
              <div style="margin-bottom: 10px;">service@beefintech.hk</div>
              <div>info@beefintech.hk</div>
            </div>
          </div>
          <div class="phone">
            <img
              src="@/assets/images/home/phone.png"
              width="17px"
              height="20px"
            />
            <!-- <span>3957 3259</span> -->
            <div>
              <div style="margin-bottom: 10px;">+86-15986790622</div>
              <div>+852-55751661</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const navList = ["產品服務", "安全中心", "最新資訊", "關於我們", "人才招聘"];
const thirdPartyList = [
  { img: require("@/assets/images/home/facebook.png") },
  { img: require("@/assets/images/home/WeChat.png") },
  { img: require("@/assets/images/home/形状 531@2x.png") },
];

export default {
  name: "GlobalFooter",
  data() {
    return {
      navList,
      thirdPartyList,
    };
  },
};
</script>
<style lang="less" scoped>
.global_footer {
  height: 370px;
  width: 100%;
  background: #30343f;
  .inner {
    width: 1200px;
    margin: 0 auto;

    .nav {
      height: 65px;
      border: 1px solid #434651;
      border-radius: 5px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-family: PingFang HK;
      font-weight: 600;
      color: #ffffff;
      span {
        cursor: pointer;
      }
    }
    .content {
      height: 305px;
      padding: 60px 0 35px 0;
      display: flex;
      .left {
        flex-grow: 1;
        padding-left: 30px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .logo_img {
          width: 306px;
          height: 86px;
        }
        .third_party {
          & > img {
            width: 56px;
            height: 56px;
            margin-right: 25px;
          }
        }
      }
      .right {
        flex-grow: 1;
        display: flex;
        flex-flow: column-reverse;
        justify-content: end;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);

        .copyright {
          font-size: 14px;
          line-height: 14px;
          font-family: sans-serif;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.3;
        }
        .address,
        .email,
        .phone {
          display: flex;
          line-height: 16px;
          margin-bottom: 25px;
          img{
            margin-right: 24px;
          }
        }

        .beian {
          font-size: 14px;
          font-family: sans-serif;
          line-height: 16px;
          margin-bottom: 25px;
          a {
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
